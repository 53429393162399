<a class="badge badge-primary"
   [attr.aria-label]="'search.filters.remove' | translate:{ type: ('search.filters.applied.f.' + appliedFilter.filter) | translate, value: appliedFilter.label }"
   [routerLink]="searchLink"
   [queryParams]="(removeParameters$ | async)"
   (click)="searchFilterService.minimizeAll()">
  <span class="d-flex">
    <span class="flex-grow-1 text-left">{{ ('search.filters.applied.f.' + appliedFilter.filter) | translate}}{{'search.filters.applied.operator.' + appliedFilter.operator | translate}}: {{'search.filters.' + appliedFilter.filter + '.' + appliedFilter.label | translate: { default: appliedFilter.label } }}</span>
    <span class="pl-1" aria-hidden="true">×</span>
  </span>
</a>
