<footer>
 <div>
</div>
 
 <div>


 </div>
 
</footer>





<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div _ngcontent-dspace-angular-c197="" class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 _ngcontent-dspace-angular-c197="" class="text-uppercase" style="color: #000000;">QUICK LINKS</h5>



          <ul class="list-unstyled mb-0">
            <li>
              <a href=" https://www.nuc.edu.ng" class="">NUC Website</a>
            </li>
            <li>
              <a href=" https://www.ngren.edu.ng" class="">NgREN Website</a>
            </li>
           

          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div _ngcontent-dspace-angular-c197="" class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 _ngcontent-dspace-angular-c197="" class="text-uppercase"style="color: #000000;">SOCIAL MEDIA</h5>
	  
          <ul class="list-unstyled mb-0">
            <li>
           
	      <a href="https://www.facebook.com/NgRENBroadBand/"><img src="assets/dspace/images/facebook.png" alt="Find us on Facebook"></a>
	      <a href="https://twitter.com/NigerianREN"><img src="assets/dspace/images/twitter.png" alt="Follow us on Twitter"></a>
	      <a href="https://www.youtube.com/channel/UCu2UaNcM3dOWkhL86W4XRUQ"><img src="assets/dspace/images/youtube.png" alt="Watch us on  Youtube channel"></a>
	      <a href="https://www.ngren.edu.ng"><img src="assets/dspace/images/instagram.png" alt="Follow us Instagram"></a>
	      <a href="https://www.linkedin.com/in/dsp-nuc-92070b223/"><img src="assets/dspace/images/linkedin.png" alt="Follow us Linkedin"></a>
	   

           </li>
          </ul>
        </div>
        <!--Grid column-->

	<!--Grid column-->

	<div _ngcontent-dspace-angular-c197="" class="col-lg-4 col-md-6 mb-4 mb-lg-0"><h5 _ngcontent-dspace-angular-c197="" class="text-uppercase" style="color: #000000;">Contact Us</h5>
	  <ul _ngcontent-dspace-angular-c197="" class="list-unstyled mb-0"><li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;" href="https://www.google.com/maps/place/Nigerian+Research+and+Education+Network/@9.0824095,7.4883035,18z/data=!4m14!1m7!3m6!1s0x104e0a5a5f01ac61:0x9d935fb7cac4a5b8!2sNational+Universities+Commission!8m2!3d9.0819433!4d7.490063!16s%2Fg%2F11rvlrh9w4!3m5!1s0x104e0a5a58598ddf:0x8ea612e85900d857!8m2!3d9.0814327!4d7.4893743!16s%2Fg%2F1pxw4fm_v?entry=ttu">National Universities Commission</a></li>
		<li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;" href="https://www.google.com/maps/place/Nigerian+Research+and+Education+Network/@9.0824095,7.4883035,18z/data=!4m14!1m7!3m6!1s0x104e0a5a5f01ac61:0x9d935fb7cac4a5b8!2sNational+Universities+Commission!8m2!3d9.0819433!4d7.490063!16s%2Fg%2F11rvlrh9w4!3m5!1s0x104e0a5a58598ddf:0x8ea612e85900d857!8m2!3d9.0814327!4d7.4893743!16s%2Fg%2F1pxw4fm_v?entry=ttu">Peter Okebukola Building</a></li>
		<li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;" href="https://www.google.com/maps/place/Nigerian+Research+and+Education+Network/@9.0824095,7.4883035,18z/data=!4m14!1m7!3m6!1s0x104e0a5a5f01ac61:0x9d935fb7cac4a5b8!2sNational+Universities+Commission!8m2!3d9.0819433!4d7.490063!16s%2Fg%2F11rvlrh9w4!3m5!1s0x104e0a5a58598ddf:0x8ea612e85900d857!8m2!3d9.0814327!4d7.4893743!16s%2Fg%2F1pxw4fm_v?entry=ttu">No. 26 Aguiyi Ironsi Street, Maitama,</a></li>
		<li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;" href="https://www.google.com/maps/place/Nigerian+Research+and+Education+Network/@9.0824095,7.4883035,18z/data=!4m14!1m7!3m6!1s0x104e0a5a5f01ac61:0x9d935fb7cac4a5b8!2sNational+Universities+Commission!8m2!3d9.0819433!4d7.490063!16s%2Fg%2F11rvlrh9w4!3m5!1s0x104e0a5a58598ddf:0x8ea612e85900d857!8m2!3d9.0814327!4d7.4893743!16s%2Fg%2F1pxw4fm_v?entry=ttu">Abuja, FCT</a></li>
		<li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;">Phone: +(234) 802 745 5412 </a></li>
		<li _ngcontent-dspace-angular-c197=""><a _ngcontent-dspace-angular-c197="" style="color: #000000;">Email: info&#64;ngren.edu.ng</a></li>
	  </ul>
	</div>
       
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
   </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        <a class="text-white"
           href="https://niris.ngren.edu.ng/">NIRIS</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.ngren.edu.ng/">Hosted by NgREN</a>
      </p>
      <ul class="footer-info list-unstyled d-flex justify-content-center mb-0">
        <li>
          <button class="btn btn-link text-white" type="button" (click)="showCookieSettings()">
            {{ 'footer.link.cookies' | translate}}
          </button>
        </li>
        <li *ngIf="showPrivacyPolicy">
          <a class="btn text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li *ngIf="showEndUserAgreement">
          <a class="btn text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li *ngIf="showSendFeedback$ | async">
          <a class="btn text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
    <div *ngIf="coarLdnEnabled$ | async" class="notify-enabled text-white">
      <a class="coar-notify-support-route" routerLink="info/coar-notify-support">
        <img class="n-coar" src="assets/images/n-coar.svg" [attr.alt]="'menu.header.image.logo' | translate" />
        {{ 'footer.link.coar-notify-support' | translate }}
      </a>
    </div>
  </div>
  <!-- Copyright -->
</footer>


