<ng-container *ngIf="derivedSearchResult$ | async">
    <ds-listable-object-component-loader
            [viewMode]="ViewModes.ListElement"
            [object]="derivedSearchResult$ | async" [linkType]="LinkTypes.None"
            [context]="badgeContext"></ds-listable-object-component-loader>
    <div class="row">
        <div [ngClass]="showThumbnails ? 'offset-3 offset-md-2  pl-3' : ''">
            <ds-workspaceitem-actions [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-workspaceitem-actions>
        </div>
    </div>
</ng-container>
<ds-loading
        *ngIf="(derivedSearchResult$ | async) === undefined"
        [showMessage]="false"></ds-loading>
