<ds-item-list-preview [item]="dso"
                      [object]="object"
                      [badgeContext]="badgeContext"></ds-item-list-preview>

<div class="row">
  <div [ngClass]="showThumbnails ? 'offset-3 offset-md-2 pl-3' : ''">
    <ds-item-actions [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-item-actions>
  </div>
</div>

