<ng-container *ngVar="(objects$ | async) as objects">
  <h1 *ngIf="displayTitle">{{title | translate}}</h1>
  <ds-starts-with-loader [paginationId]="paginationConfig?.id" [startsWithOptions]="startsWithOptions" [type]="type">
  </ds-starts-with-loader>
  <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
      <div *ngIf="shouldDisplayResetButton$ |async" class="mb-2 reset">
        <ds-results-back-button [back]="back" [buttonLabel]="buttonLabel"></ds-results-back-button>
      </div>
    <ds-viewable-collection
      [config]="paginationConfig"
      [sortConfig]="sortConfig"
      [showPaginator]="showPaginator"
      [objects]="objects"
      (prev)="goPrev()"
      (next)="goNext()">
    </ds-viewable-collection>
  </div>
  <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
  <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
  <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0">
    <div *ngIf="shouldDisplayResetButton$ |async" class="d-inline-block mb-4 reset">
      <ds-results-back-button [back]="back" [buttonLabel]="buttonLabel"></ds-results-back-button>
    </div>
    <div class="alert alert-info w-100" role="alert">
      {{'browse.empty' | translate}}
    </div>
  </div>
</ng-container>

