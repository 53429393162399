<ng-container *ngVar="(actionRD$ | async)?.payload as workflowAction">
  <div class="mt-1 mb-3 space-children-mr">
    <ds-claimed-task-actions-loader *ngFor="let option of workflowAction?.options"
                                    [item]="item"
                                    [option]="option"
                                    [object]="object"
                                    [workflowitem]="workflowitem"
                                    (processCompleted)="this.processCompleted.emit($event)">
    </ds-claimed-task-actions-loader>

    <button class="btn btn-primary workflow-view" ngbTooltip="{{'submission.workflow.generic.view-help' | translate}}"
      [routerLink]="[getWorkflowItemViewRoute(workflowitem)]">
      <i class="fa fa-info-circle"></i> {{"submission.workflow.generic.view" | translate}}
    </button>
  </div>
</ng-container>
