<ng-container *ngVar="(workflowitemRD$ | async)?.payload as workflowitem">
  <div class="alert alert-secondary w-100" role="alert">
    <h4 class="alert-heading mb-0">{{ 'claimed-declined-search-result-list-element.title' | translate }}</h4>
    <ds-item-list-preview *ngIf="workflowitem"
                          [item]="(workflowitem?.item | async)?.payload"
                          [object]="object"
                          [badgeContext]="badgeContext"
                          [showSubmitter]="showSubmitter"></ds-item-list-preview>
  </div>
</ng-container>
