<form class="form-login"
      (ngSubmit)="submit()"
      [formGroup]="form" novalidate>
  <input [attr.aria-label]="'login.form.email' |translate"
         autocomplete="username"
         autofocus
         class="form-control form-control-lg position-relative"
         formControlName="email"
         placeholder="{{'login.form.email' | translate}}"
         required
         type="email"
         [attr.data-test]="'email' | dsBrowserOnly">
  <input [attr.aria-label]="'login.form.password' |translate"
         autocomplete="current-password"
         class="form-control form-control-lg position-relative mb-3"
         placeholder="{{'login.form.password' | translate}}"
         formControlName="password"
         required
         type="password"
         [attr.data-test]="'password' | dsBrowserOnly">
  <div *ngIf="(error | async) && hasError" class="alert alert-danger" role="alert"
       @fadeOut>{{ (error | async) | translate }}</div>
  <div *ngIf="(message | async) && hasMessage" class="alert alert-info" role="alert"
       @fadeOut>{{ (message | async) | translate }}</div>

  <button class="btn btn-lg btn-primary btn-block mt-3" type="submit" [attr.data-test]="'login-button' | dsBrowserOnly"
          [disabled]="!form.valid"><i class="fas fa-sign-in-alt"></i> {{"login.form.submit" | translate}}</button>
</form>

<ng-container *ngIf="canShowDivider$ | async">
  <div class="mt-2">
    <a class="dropdown-item" *ngIf="canRegister$ | async" [routerLink]="[getRegisterRoute()]"
       [attr.data-test]="'register' | dsBrowserOnly" role="menuitem">{{"login.form.new-user" | translate}}</a>
    <a class="dropdown-item" *ngIf="canForgot$ | async" [routerLink]="[getForgotRoute()]"
       [attr.data-test]="'forgot' | dsBrowserOnly" role="menuitem">{{"login.form.forgot-password" | translate}}</a>
  </div>
</ng-container>
