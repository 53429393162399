<section class="comcol-page-browse-section">
  <div class="browse-by-metadata w-100">
    <ds-browse-by *ngIf="(loading$ | async) !== true" class="col-xs-12 w-100"
                         title="{{'browse.title' | translate:{
                           field: 'browse.metadata.' + browseId | translate,
                           startsWith: (startsWith)? ('browse.startsWith' | translate: { startsWith: '&quot;' + startsWith + '&quot;' }) : '',
                           value: (value)? '&quot;' + value + '&quot;': ''
                         } }}"
                         [displayTitle]="displayTitle"
                         [objects$]="(items$ !== undefined)? items$ : browseEntries$"
                         [paginationConfig]="(currentPagination$ |async)"
                         [sortConfig]="(currentSort$ |async)"
                         [type]="startsWithType"
                         [startsWithOptions]="startsWithOptions"
                         (prev)="goPrev()"
                         (next)="goNext()">
    </ds-browse-by>
    <ds-loading *ngIf="loading$ | async"
                       message="{{'loading.browse-by-page' | translate}}"></ds-loading>
  </div>
</section>
