<ng-container>
    <h3>{{ 'search.sidebar.settings.title' | translate}}</h3>
    <div class="result-order-settings">
    <ds-sidebar-dropdown *ngIf="sortOptionsList"
                         [id]="'search-sidebar-sort'"
                         [label]="'search.sidebar.settings.sort-by'"
                         (changed)="reloadOrder($event)">
        <option *ngFor="let sortOptionsEntry of sortOptionsList"
                [value]="sortOptionsEntry.field + ',' + sortOptionsEntry.direction.toString()"
                [selected]="sortOptionsEntry.field === currentSortOption?.field && sortOptionsEntry.direction === (currentSortOption?.direction)? 'selected': null">
            {{'sorting.' + sortOptionsEntry.field + '.' + sortOptionsEntry.direction | translate}}
        </option>
    </ds-sidebar-dropdown>
    </div>
    <ds-page-size-selector></ds-page-size-selector>
</ng-container>
