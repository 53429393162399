<ng-template dsDynamicComponentLoader>
</ng-template>
<div #badges class="position-absolute ml-1">
    <div class="workflow-badge">
        <span class="badge badge-info">{{ "admin.workflow.item.workspace" | translate }}</span>
    </div>
</div>
<ul #buttons class="list-group list-group-flush">
    <li class="list-group-item">
      <ds-workspace-item-admin-workflow-actions-element [small]="true"
                                                        [supervisionOrderList]="supervisionOrder$ | async"
                                                        [wsi]="dso"
                                                        (create)="reloadObject($event)"
                                                        (delete)="reloadObject($event)"></ds-workspace-item-admin-workflow-actions-element>
    </li>
</ul>
