<div class="row">
    <div *ngIf="showThumbnails" class="col-3 col-md-2">
        <a *ngIf="linkType !== linkTypes.None" [target]="(linkType === linkTypes.ExternalLink) ? '_blank' : '_self'"
           [attr.rel]="(linkType === linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
           [routerLink]="[itemPageRoute]" class="dont-break-out">
            <ds-thumbnail [thumbnail]="dso?.thumbnail | async"
                                 [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                                 [alt]="'thumbnail.orgunit.alt'"
                                 [placeholder]="'thumbnail.orgunit.placeholder'">
            </ds-thumbnail>
        </a>
        <span *ngIf="linkType === linkTypes.None" class="dont-break-out">
            <ds-thumbnail [thumbnail]="dso?.thumbnail | async"
                          [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                          [alt]="'thumbnail.orgunit.alt'"
                          [placeholder]="'thumbnail.orgunit.placeholder'">
            </ds-thumbnail>
        </span>
    </div>
    <div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">
        <ds-badges *ngIf="showLabel" [object]="dso" [context]="context"></ds-badges>
        <ds-truncatable [id]="dso.id">
            <a *ngIf="linkType !== linkTypes.None" [target]="(linkType === linkTypes.ExternalLink) ? '_blank' : '_self'"
               [attr.rel]="(linkType === linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
               [routerLink]="[itemPageRoute]" class="lead"
               [innerHTML]="dsoTitle || ('orgunit.listelement.no-title' | translate)"></a>
            <span *ngIf="linkType === linkTypes.None"
                  class="lead"
                  [innerHTML]="dsoTitle || ('orgunit.listelement.no-title' | translate)"></span>
            <span class="text-muted">
      <span *ngIf="dso.allMetadata(['dc.description']).length > 0"
            class="item-list-org-unit-description">
            <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                    [innerHTML]="firstMetadataValue('dc.description')"></span>
            </ds-truncatable-part>
      </span>
  </span>
        </ds-truncatable>
    </div>
</div>
