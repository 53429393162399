<h3 *ngIf="inPlaceSearch">{{filterLabel+'.filters.head' | translate}}</h3>
<h2 *ngIf="!inPlaceSearch">{{filterLabel+'.filters.head' | translate}}</h2>
<div *ngIf="(filters | async)?.hasSucceeded">
    <div *ngFor="let filter of (filters | async)?.payload; trackBy: trackUpdate">
        <ds-search-filter [scope]="currentScope" [filter]="filter" [inPlaceSearch]="inPlaceSearch" [refreshFilters]="refreshFilters"></ds-search-filter>
    </div>
</div>
<button *ngIf="inPlaceSearch" class="btn btn-primary" [routerLink]="[searchLink]" [queryParams]="clearParams | async" (click)="minimizeFilters()" queryParamsHandling="merge" role="button">
  <i class="fas fa-undo"></i> {{"search.filters.reset" | translate}}
</button>
