<div class="navbar-nav mr-auto" *ngIf="(isMobile$ | async) !== true; else mobileButtons" data-test="auth-nav">
  <div *ngIf="(isAuthenticated | async) !== true && (showAuth | async)"
      class="nav-item"
      (click)="$event.stopPropagation();">
    <div ngbDropdown #loginDrop="ngbDropdown" display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>
      <a href="javascript:void(0);" class="dropdownLogin px-0.5" [attr.aria-label]="'nav.login' |translate"
         (click)="$event.preventDefault()" [attr.data-test]="'login-menu' | dsBrowserOnly"
         role="menuitem"
         aria-haspopup="menu"
         aria-controls="loginDropdownMenu"
         [attr.aria-expanded]="loginDrop.isOpen()"
         ngbDropdownToggle>{{ 'nav.login' | translate }}</a>
      <div id="loginDropdownMenu" [ngClass]="{'pl-3 pr-3': (loading | async)}" ngbDropdownMenu
           role="menu"
           [attr.aria-label]="'nav.login' | translate">
        <ds-log-in
          [isStandalonePage]="false"></ds-log-in>
      </div>
    </div>
  </div>
  <div *ngIf="(isAuthenticated | async) && (showAuth | async)" class="nav-item">
    <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>
      <a href="javascript:void(0);"
         role="menuitem"
         [attr.aria-label]="'nav.user-profile-menu-and-logout' | translate"
         aria-controls="user-menu-dropdown"
         (click)="$event.preventDefault()" [title]="'nav.user-profile-menu-and-logout' | translate"
         class="dropdownLogout px-1"
         [attr.data-test]="'user-menu' | dsBrowserOnly"
         ngbDropdownToggle>
        <i class="fas fa-user-circle fa-lg fa-fw"></i></a>
      <div id="logoutDropdownMenu" ngbDropdownMenu>
        <ds-user-menu [inExpandableNavbar]="false"></ds-user-menu>
      </div>
    </div>
  </div>
</div>


<ng-template #mobileButtons>
  <div data-test="auth-nav">
    <a *ngIf="(isAuthenticated | async) !== true" routerLink="/login" routerLinkActive="active" class="loginLink px-0.5" role="button">
      {{ 'nav.login' | translate }}<span class="sr-only">(current)</span>
    </a>
    <a *ngIf="(isAuthenticated | async)" role="button" [attr.aria-label]="'nav.logout' |translate" [title]="'nav.logout' | translate" routerLink="/logout" routerLinkActive="active" class="logoutLink px-1">
      <i class="fas fa-sign-out-alt fa-lg fa-fw"></i>
      <span class="sr-only">(current)</span>
    </a>
  </div>
</ng-template>

<!-- Do not use ul/li in this menu as it breaks e2e accessibility tests -->
