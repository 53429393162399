<nav [ngClass]="{'open': (menuCollapsed | async) !== true}"
     [@slideMobileNav]="(windowService.isXsOrSm() | async) !== true ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
     class="navbar navbar-light navbar-expand-md px-md-0 pt-md-0 pt-3 navbar-container" role="navigation"
     [attr.aria-label]="'nav.main.description' | translate" id="main-navbar">
  <!-- TODO remove navbar-container class when https://github.com/twbs/bootstrap/issues/24726 is fixed -->
  <div class="navbar-inner-container w-100" [class.container]="(isMobile$ | async) !== true">
    <div class="w-100">
      <div id="collapsingNav">
        <ng-container *ngIf="(isMobile$ | async) && (isAuthenticated$ | async)">
          <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
        </ng-container>
        <div class="navbar-nav align-items-md-center mr-auto shadow-none gapx-3">
          <ng-container *ngFor="let section of (sections | async)">
            <ng-container
              *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
