<ng-container *ngIf="(workflowitem$ | async) && (item$ | async)">
  <ds-item-list-preview [item]="item$.value"
                               [object]="object"
                               [showSubmitter]="showSubmitter"
                               [badgeContext]="badgeContext"
                               [workflowItem]="workflowitem$.value"></ds-item-list-preview>

  <!-- Display duplicate alert, if feature enabled and duplicates detected -->
  <ng-container *ngVar="(duplicates$|async)?.length as duplicateCount">
    <div [ngClass]="'row'" *ngIf="duplicateCount > 0">
      <div [ngClass]="'col-2'"></div>
      <div [ngClass]="'col-10'">
        <div class="d-flex alert alert-warning w-100">
          {{ duplicateCount }} {{ 'submission.workflow.tasks.duplicates' | translate }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div [ngClass]="showThumbnails ? 'offset-3 offset-md-2 pl-3' : ''">
      <ds-pool-task-actions id="actions"
                            [item]="item$.value"
                            [object]="dso"
                            [workflowitem]="workflowitem$.value"
                            (processCompleted)="this.reloadedObject.emit($event.reloadedObject)"></ds-pool-task-actions>
    </div>
  </div>
</ng-container>
