<ng-template #descTemplate>
  <span class="text-muted">
      <span *ngIf="mdRepresentation.allMetadata(['person.jobTitle']).length > 0"
            class="item-list-job-title">
              <span *ngFor="let value of mdRepresentation.allMetadataValues(['person.jobTitle']); let last=last;">
                  <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
              </span>
      </span>
  </span>
</ng-template>
<ds-truncatable [id]="mdRepresentation.id">
  <a [routerLink]="[itemPageRoute]"
     [innerHTML]="mdRepresentation.getValue()"
     [ngbTooltip]="mdRepresentation.allMetadata(['person.jobTitle']).length > 0 ? descTemplate : null"></a>
</ds-truncatable>
