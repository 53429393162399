<ds-alert *ngIf="description" [content]="description" [type]="AlertType.Info"></ds-alert>
<div class="treeview-header row mb-1">
  <div class="col-12">
    <div class="input-group">
      <input type="text" class="form-control" [(ngModel)]="searchText" (keyup.enter)="search()"
             [placeholder]="'vocabulary-treeview.search.form.search-placeholder' | translate">
      <div class="input-group-append" id="button-addon4">
        <button class="btn btn-outline-primary" type="button" (click)="search()" [disabled]="!isSearchEnabled()">
          {{'vocabulary-treeview.search.form.search' | translate}}
        </button>
        <button class="btn btn-outline-secondary" type="button" (click)="reset()">
          {{'vocabulary-treeview.search.form.reset' | translate}}
        </button>
        <button *ngIf="showAdd && this.vocabularyOptions.closed" class="btn btn-outline-primary" type="button" (click)="add()">
          {{'vocabulary-treeview.search.form.add' | translate}}
        </button>
        <button class="btn btn-outline-primary" type="button" (click)="add()" [disabled]="this.vocabularyOptions.closed">
          {{'vocabulary-treeview.search.form.add' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="treeview-container">
  <ds-loading *ngIf="loading | async" [showMessage]="false"></ds-loading>
  <h2 *ngIf="(loading | async) !== true && dataSource.data.length === 0" class="h4 text-center text-muted mt-4" >
    <span>{{'vocabulary-treeview.search.no-result' | translate}}</span>
  </h2>
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Leaf node -->
    <cdk-tree-node *cdkTreeNodeDef="let node" cdkTreeNodePadding class="d-flex">
      <span aria-hidden="true" type="button" class="btn btn-default px-2 mr-1" cdkTreeNodeToggle>
        <i class="fas fa-fw fa-angle-right invisible"></i>
      </span>
          <label *ngIf="multiSelect" class="d-flex align-items-center m-0 p-0 form-check"
                 [class.text-success]="node.isSelected"
                 [ngbTooltip]="node.item?.otherInformation?.note"
                 [openDelay]="500"
                 container="body"
          >
            <input class="mr-2" type="checkbox"
                   [disabled]="!node.item?.selectable"
                   [(ngModel)]="node.isSelected"
                   [checked]="node.isSelected"
                   (change)="onSelect(node.item)"
            >
            <span>{{node.item.display}}</span>
          </label>
          <button *ngIf="!multiSelect" class="btn btn-outline-link btn-sm text-left"
                  [class.text-success]="node.isSelected"
                  [disabled]="!node.item?.selectable"
                  [ngbTooltip]="node.item?.otherInformation?.note"
                  [openDelay]="500"
                  container="body"
                  (click)="onSelect(node.item)">
          <span>{{node.item.display}}</span>
        </button>
    </cdk-tree-node>

    <!-- expandable node -->
    <cdk-tree-node *cdkTreeNodeDef="let node; when: hasChildren" cdkTreeNodePadding class="d-flex">
        <button type="button" class="btn btn-default px-2 mr-1" cdkTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name"
                (click)="loadChildren(node)">
          <i class="fas fa-fw {{treeControl.isExpanded(node) ? 'fa-angle-down' : 'fa-angle-right'}}"></i>
        </button>

          <label *ngIf="multiSelect" class="d-flex align-items-center m-0 p-0 form-check"
                [class.text-success]="node.isSelected"
                [ngbTooltip]="node.item?.otherInformation?.note"
                [openDelay]="500"
                container="body">
            <input class="mr-2" type="checkbox"
                   [disabled]="!node.item?.selectable"
                   [(ngModel)]="node.isSelected"
                   [checked]="node.isSelected"
                   (change)="onSelect(node.item)"
            >
            <span>{{node.item.display}}</span>
          </label>
          <button *ngIf="!multiSelect" class="btn btn-outline-link btn-sm text-left"
                  [class.text-success]="node.isSelected"
                  [disabled]="!node.item?.selectable"
                  [ngbTooltip]="node.item?.otherInformation?.note"
                  [openDelay]="500"
                  container="body"
                  (click)="onSelect(node.item)">
            <span>{{node.item.display}}</span>
          </button>
    </cdk-tree-node>

    <cdk-tree-node *cdkTreeNodeDef="let node; when: isLoadMore" cdkTreeNodePadding>
      <button class="btn btn-outline-secondary btn-sm" (click)="loadMore(node.loadMoreParentItem)">
        {{'vocabulary-treeview.load-more' | translate}}...
      </button>
    </cdk-tree-node>

    <cdk-tree-node *cdkTreeNodeDef="let node; when: isLoadMoreRoot">
      <button class="btn btn-outline-secondary btn-sm" (click)="loadMoreRoot(node)">
        {{'vocabulary-treeview.load-more' | translate}}...
      </button>
    </cdk-tree-node>
  </cdk-tree>
</div>
