<section>
  <h1 *ngIf="displayTitle">
    {{ ('browse.title') | translate:{
    field: 'browse.metadata.' + vocabularyName | translate,
    startsWith: '',
    value: '',
  } }}
  </h1>
  <div class="mb-3">
    <ds-vocabulary-treeview [description]="description"
                            [vocabularyOptions]=vocabularyOptions
                            [multiSelect]="true"
                            [showAdd]="false"
                            (select)="onSelect($event)"
                            (deselect)="onDeselect($event)">
    </ds-vocabulary-treeview>
  </div>
  <a class="btn btn-primary"
     [routerLink]="['/search']"
     [queryParams]="queryParams"
     [queryParamsHandling]="'merge'">
    {{ 'browse.taxonomy.button' | translate }}</a>
</section>
