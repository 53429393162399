<ds-pagination
  [paginationOptions]="config"
  [collectionSize]="objects?.payload?.totalElements"
  [sortOptions]="sortConfig"
  [objects]="objects"
  [hideGear]="hideGear"
  [hidePaginationDetail]="hidePaginationDetail"
  [hidePagerWhenSinglePage]="hidePagerWhenSinglePage"
  [showPaginator]="showPaginator"
  (pageChange)="onPageChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"
  (sortDirectionChange)="onSortDirectionChange($event)"
  (sortFieldChange)="onSortFieldChange($event)"
  (paginationChange)="onPaginationChange($event)"
  (prev)="goPrev()"
  (next)="goNext()">
    <div class="row mt-2" *ngIf="objects?.hasSucceeded" @fadeIn>
      <div class="col"
           *ngFor="let object of objects?.payload?.page">
        <ds-listable-object-component-loader [object]="object"
                                             [viewMode]="viewMode"
                                             [context]="context"
                                             [showThumbnails]="showThumbnails"
                                             (contentChange)="contentChange.emit($event)"></ds-listable-object-component-loader>
      </div>
    </div>
  <ds-error *ngIf="objects.hasFailed" message="{{'error.objects' | translate}}"></ds-error>
  <ds-loading *ngIf="objects.isLoading" message="{{'loading.objects' | translate}}"></ds-loading>
</ds-pagination>
