<ng-container *ngVar="(supervisionOrderEntries$ | async) as supervisionOrders">
  <div class="item-list-supervision" *ngIf="supervisionOrders?.length > 0">
    <div>
      <span>{{'workflow-item.search.result.list.element.supervised-by' | translate}} </span>
    </div>
    <div>
      <a class="badge badge-primary mr-1 mb-1 text-capitalize mw-100 text-truncate" *ngFor="let supervisionOrder of supervisionOrders" data-test="soBadge"
         [ngbTooltip]="'workflow-item.search.result.list.element.supervised.remove-tooltip' | translate"
         (click)="$event.preventDefault(); $event.stopImmediatePropagation(); deleteSupervisionOrder(supervisionOrder)" aria-label="Close">
        {{ dsoNameService.getName(supervisionOrder.group) }}
        <span aria-hidden="true"> ×</span>
      </a>
    </div>
  </div>
</ng-container>
