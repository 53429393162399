  <div class="add" *ngIf="(moreThanOne$ | async) !== true">
    <button class="btn btn-lg btn-primary mt-1 ml-2" [attr.aria-label]="'mydspace.new-submission' | translate"
            [disabled]="(initialized$ | async) !== true" (click)="openDialog(singleEntity)" role="button">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </button>
  </div>
  <div class="add w-100" display="dynamic" placement="bottom-right"
       ngbDropdown
       *ngIf="(moreThanOne$ | async)">
    <button class="btn btn-lg btn-primary mt-1 ml-2" id="dropdownSubmission" ngbDropdownToggle
            type="button"  [disabled]="(initialized$ | async) !== true"
            [attr.aria-label]="'mydspace.new-submission' | translate"
            [attr.data-test]="'submission-dropdown' | dsBrowserOnly"
            title="{{'mydspace.new-submission' | translate}}">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      <span class="caret" aria-hidden="true"></span>
    </button>
    <div ngbDropdownMenu
         class="dropdown-menu p-0"
         id="entityControlsDropdownMenu"
         aria-labelledby="dropdownSubmission">
      <ds-entity-dropdown [isSubmission]="true" (selectionChange)="openDialog($event)"></ds-entity-dropdown>
    </div>
  </div>
