<div *ngIf="!canActivate" class="dso-button-menu mb-1"
     [ngbTooltip]="itemModel.text | translate">
    <a *ngIf="!section.model.disabled" class="btn btn-dark btn-sm"
       [routerLink]="itemModel.link">
        <i class="fas fa-{{section.icon}} fa-fw" aria-hidden="true"></i>
        <span class="sr-only">{{itemModel.text | translate}}</span>
    </a>
    <button *ngIf="section.model.disabled" class="btn btn-dark btn-sm" [disabled]="true">
        <i class="fas fa-{{section.icon}} fa-fw" aria-hidden="true"></i>
        <span class="sr-only">{{itemModel.text | translate}}</span>
    </button>
</div>

<div *ngIf="canActivate" class="dso-button-menu mb-1"
     [ngbTooltip]="itemModel.text | translate">
    <button class="btn btn-dark btn-sm" [disabled]="section.model.disabled"
            (click)="activate($event)">
        <i class="fas fa-{{section.icon}} fa-fw" aria-hidden="true"></i>
        <span class="sr-only">{{itemModel.text | translate}}</span>
    </button>
</div>
