<ul class="scrollable-menu list-unstyled mb-0"
    role="menu"
    (scroll)="onScroll($event)"
    infiniteScroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="300"
    [infiniteScrollUpDistance]="1.5"
    [fromRoot]="true"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()">
  <li *ngIf="searchListEntity?.length === 0 && (isLoadingList | async) !== true">
    <button class="dropdown-item disabled" role="menuitem">
      {{'submission.sections.general.no-entity' | translate}}
    </button>
  </li>
  <li *ngFor="let listItem of searchListEntity" class="entity-item text-primary">
    <button class="dropdown-item"
            role="menuitem"
            title="{{ listItem.label }}"
            (click)="onSelect(listItem)">
      <span class="text-truncate font-weight-bold">{{ listItem.label.toLowerCase() + '.listelement.badge' | translate }}</span>
    </button>
  </li>
  <li *ngIf="(isLoadingList | async)">
    <button class="dropdown-item disabled" role="menuitem">
      <ds-loading message="{{'loading.default' | translate}}">
      </ds-loading>
    </button>
  </li>
</ul>
